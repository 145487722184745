import React, { useEffect, useState, useMemo } from 'react'
import { arrDateBanner } from '../../utils/createTime'
import { graphql, useStaticQuery } from 'gatsby'
import { useIntl, Link } from 'gatsby-plugin-intl-v6'
import BannerImg from '../../static/images/home/banner.png'
import { StaticImage } from 'gatsby-plugin-image'
import { Carousel } from 'antd';


// interface MdxEdge {
//   node: {
//     slug: string
//     frontmatter: {
//       title: string
//       date: string
//       language: string
//     }
//   }
// }

export default function Banner() {
  const intl = useIntl()
  const locale = intl.locale
  const [data, setData] = useState([])
  
  // const { allMdx } = useStaticQuery(graphql`
  //   query {
  //     allMdx (
  //       filter: { slug: { glob: "announcement/*" } },
  //       sort: { fields: [frontmatter___date], order: DESC }
  //     ) {
  //       edges {
  //         node {
  //           slug
  //           frontmatter {
  //             title
  //             date(formatString: "YYYY-MM-DD")
  //             language
  //           }
  //         }
  //       }
  //     }
  //   }
  // `)

  // const filteredData = useMemo(() => {
  //   const filtered = (allMdx?.edges || []).filter(
  //     (edge: any) => edge.node.frontmatter?.language === locale
  //   )
  //   return filtered.slice(0, 2)
  // }, [allMdx?.edges, locale])

  // useEffect(() => {
  // }, [])

  let bannerlist = [
    {
      title: intl.formatMessage({ id: 'AB Wallet' }),
      content: intl.formatMessage({ id: 'Decentralized digital wallet' }),
      url: '/abwallet/',
      isLink: true
    },
    {
      title: intl.formatMessage({ id: 'Get AB' }),
      content: intl.formatMessage({ id: 'Earned from the Ecosystem' }),
      url: '/getab/',
      isLink: true
    },
    {
      title: intl.formatMessage({ id: 'AB Explorer' }),
      content: intl.formatMessage({ id: 'Explorer & Analytics platform' }),
      url: 'https://explorer.ab.org/',
      isLink: false
    },
    // {
    //   title: intl.formatMessage({ id: 'NewBridge' }),
    //   content: intl.formatMessage({ id: 'Realize asset exchange' }),
    //   url: 'https://newbridge.network/',
    //   isLink: false
    // }
  ]
  return (
    <>
      <div className={'banner-pc container'}>
        <div className={'banner-centent'}>
          <div className={'banner-news'}>
            {/* {filteredData.length > 0 ? (
              filteredData.map((edge: MdxEdge, index: number) => {
                const { frontmatter, slug } = edge.node
                return (
                  <p className="mb-2" key={index}>
                    <Link to={`/${slug}`}>
                      {frontmatter?.title}
                      {arrDateBanner(frontmatter?.date)}
                    </Link>
                  </p>
                )
              })
            ) : null} */}
            <div className={'banner-title'}>
              <span>$</span>AB
            </div>
            <div className={'banner-sub-title'}>
              {intl.formatMessage({ id: 'AB Project build a decentralised future, providing an efficient and secure infrastructure for DApps and diverse assets.' })}
            </div>
            <div className='banner-image'>
              <img src={BannerImg} />
            </div>
          </div>
        </div>
        <div className={'banner-link'}>
          {bannerlist.map((item, index) => {
            return (
              <div key={index}>
                <div className='side-line'></div>
                {item.isLink == true ? (
                  <Link to={item.url}>
                    <span>{item.title}</span>
                    <i>{item.content}</i>
                  </Link>
                ) : (
                  <a href={item.url} target="_blank">
                    <span>{item.title}</span>
                    <i>{item.content}</i>
                  </a>
                )}
              </div>
            )
          })}
        </div>
      </div>
      <div className={'banner-mobile-box'}>
        <div className={'banner-mobile container'}>
          <div className={'banner-title'}>
          <span>$</span>AB
          </div>
          <div className={'banner-sub-title'}>
            {intl.formatMessage({ id: 'AB Project build a decentralised future, providing an efficient and secure infrastructure for DApps and diverse assets.' })}
          </div>
          <div className='banner-image'>
              <img src={BannerImg} />
            </div>
          {/* {filteredData.length > 0 ? (
              filteredData.map((edge: MdxEdge, index: number) => {
                const { frontmatter, slug } = edge.node
                return (
                  <p className="mb-2" key={index}>
                    <Link to={`/${slug}`}>
                      {frontmatter?.title}
                      {arrDateBanner(frontmatter?.date)}
                    </Link>
                  </p>
                )
              })
            ) : null} */}
        </div>
        <div className={'banner-mobile banner-mobile-news container'}>
          {/* <div className={'banner-news'}>
            {data && data.length > 0
              ? data.map((item: any, index) => {
                  return (
                    <p className="mb-2" key={index}>
                      {data.length == 1 ? (
                        <Link to={item.url}>
                          {item.title}
                          {arrDateBanner(item.created_at)}
                        </Link>
                      ) : (
                        <Link to={item.url}>
                          {item.title}
                          {index !== 0 ? arrDateBanner(item.created_at) : null}
                        </Link>
                      )}
                    </p>
                  )
                })
              : null}
          </div> */}
          <div className={'banner-link'}>
            {bannerlist.map((item, index) => {
              return (
                <div key={index}>
                  {item.isLink == true ? (
                    <Link to={item.url}>
                      <span><i></i>{item.title}</span>
                      <i>{item.content}</i>
                    </Link>
                  ) : (
                    <a href={item.url} target="_blank">
                      <span><i></i>{item.title}</span>
                      <i>{item.content}</i>
                    </a>
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      {/* <div className={'foundation-pc'}>
        <div className={'foundation-content'}>
          <h1>{ intl.formatMessage({ id: 'AB Foundation' }) }</h1>
          <p>{ intl.formatMessage({ id: 'The AB Foundation is dedicated to serving as a bridge between blockchain innovation and social welfare. We drive the development of the public chain ecosystem, establishing a transparent, efficient, and inclusive blockchain infrastructure to serve social good through technology.'}) }</p>
          <div>
            <Link to={'/foundation/'}>
              { intl.formatMessage({ id: 'Learn more' })}
            </Link>
          </div>
        </div>
        <div className='foundation-directors'>
          <Carousel className='h-full' autoplay>
          {filteredDirctors.length > 0 ? (
            filteredDirctors.map((edge: any, index: number) => {
              const { frontmatter, slug } = edge.node
              const descArr = frontmatter.description.split('\n')
              const avatarPath = frontmatter.enName ? frontmatter.enName.replace(' ', '') : frontmatter.title.replace(' ', '')
              const imagePath = require(`../../static/images/directors/${avatarPath}.png`).default;
              return (
                <div className='director-card' key={index}>
                  <img src={ imagePath } alt="director" className="director-avatar" />
                  <div className='director-desc'>
                    <h3>{ frontmatter.title }</h3>
                    <p className='other-rank'>{ frontmatter.otherRank }</p>
                    <p>{ descArr[0] }</p>
                    <p>{ descArr[1] }</p>
                    <p>{ descArr[2] }</p>
                  </div>
                </div>
              )
            })
          ) : null}
          </Carousel>
        </div>
      </div>
      <div className={'foundation-mobile'}>
        <div className={'foundation-content'}>
          <h1>{ intl.formatMessage({ id: 'AB Foundation' }) }</h1>
          <p>{ intl.formatMessage({ id: 'The AB Foundation is dedicated to serving as a bridge between blockchain innovation and social welfare. We drive the development of the public chain ecosystem, establishing a transparent, efficient, and inclusive blockchain infrastructure to serve social good through technology.'}) }</p>
        </div>
        
        <div className='foundation-directors'>
          <Carousel className='h-full' autoplay>
          {filteredDirctors.length > 0 ? (
            filteredDirctors.map((edge: any, index: number) => {
              const { frontmatter, slug } = edge.node
              const descArr = frontmatter.description.split('\n')
              const avatarPath = frontmatter.enName ? frontmatter.enName.replace(' ', '') : frontmatter.title.replace(' ', '')
              const imagePath = require(`../../static/images/directors/${avatarPath}.png`).default;
              return (
                <div className='director-card' key={index}>
                  <img src={ imagePath } alt="director" className="director-avatar" />
                  <div className='director-desc'>
                    <h3>{ frontmatter.title }</h3>
                    <p className='other-rank'>{ frontmatter.otherRank }</p>
                    <p>{ descArr[0] }</p>
                    <p>{ descArr[1] }</p>
                  </div>
                </div>
              )
            })
          ) : null}
          </Carousel>
        </div>
        <div className={'foundation-link'}>
            <Link to={'/foundation/'}>
              { intl.formatMessage({ id: 'Learn more' })}
            </Link>
          </div>
      </div> */}
    </>
  )
}
