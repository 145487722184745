import React from 'react'
import Banner from '../components/home/banner'
// import News from '../components/home/news'
import NormalLayout from '../components/NormalLayout'
import { PageModelTitle } from '../model/PageModel'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl-v6'
import { motion } from "framer-motion";
import { useViewportAnimation } from '../utils/interaction'

const IndexPage = () => {
  let pageModel = new PageModelTitle('Home', 'new-index')
  return <div>{NormalLayout(Main(), pageModel)}</div>
}
export default IndexPage

function Main() {
  const intl = useIntl()
  const [ref1, controls1] = useViewportAnimation(0.2);
  const [ref2, controls2] = useViewportAnimation(0.2);
  const [ref3, controls3] = useViewportAnimation(0.2);
  return (
    <>
      <Banner />
      <div className={'container'}>
        <div className={'centent-community'}>
          {/* <h2>{intl.formatMessage({ id: "AB's Community Economy" })}</h2> */}
          <ul className='in-h5'>
            <motion.li
              ref={ref1}
              initial={{ x: -100, opacity: 0 }}
              animate={controls1}
              transition={{ duration: 0.6 }}
            >
              <div>
                <StaticImage
                  placeholder="blurred"
                  className={'community-img'}
                  alt="img"
                  src="../static/images/home/icon-1.png"
                />
              </div>
              <h5>
                {intl.formatMessage({ id: 'Users Own Their Own Data' })}
              </h5>
              <p>
                {intl.formatMessage({
                  id: 'Users secure and control their personal data through cryptography, and manage their personal credit and share profits produced by their data in a pre-agreed manner.'
                })}
              </p>
            </motion.li>
            <motion.li
              ref={ref2}
              initial={{ x: 100, opacity: 0 }}
              animate={controls2}
              transition={{ duration: 0.6 }}
            >
              <div>
                <StaticImage
                  placeholder="blurred"
                  className={'community-img'}
                  alt="img"
                  src="../static/images/home/icon-2.png"
                />
              </div>
              <h5>
                {intl.formatMessage({ id: 'Better Incentive Mechanisms' })}
              </h5>
              <p>
                {intl.formatMessage({
                  id: "Replacing middlemen with technology reduces transaction costs and rewards anyone who contributes to the transaction, whether they're service providers, consumers or others connected by the value-based internet."
                })}
              </p>
            </motion.li>
            <motion.li
              ref={ref3}
              initial={{ x: -100, opacity: 0 }}
              animate={controls3}
              transition={{ duration: 0.6 }}
            >
              <div>
                <StaticImage
                  placeholder="blurred"
                  className={'community-img icon3'}
                  alt="img"
                  src="../static/images/home/icon-3.png"
                />
              </div>
              <h5>
                {intl.formatMessage({ id: 'New Organization & Cooperation' })}
              </h5>
              <p>
                {intl.formatMessage({
                  id: 'The community economy managed by non-profit foundations will replace the monopolies of middlemen managed by traditional for-profit organizations. Based on open, fair, and rational rules, every contributor will benefit from, and become part of this economy.'
                })}
              </p>
            </motion.li>
          </ul>
          <ul className='in-web'>
            <li>
              <div>
                <StaticImage
                  placeholder="blurred"
                  className={'community-img'}
                  alt="img"
                  src="../static/images/home/icon-1.png"
                />
              </div>
              <h5>
                {intl.formatMessage({ id: 'Users Own Their Own Data' })}
              </h5>
              <p>
                {intl.formatMessage({
                  id: 'Users secure and control their personal data through cryptography, and manage their personal credit and share profits produced by their data in a pre-agreed manner.'
                })}
              </p>
            </li>
            <li>
              <div>
                <StaticImage
                  placeholder="blurred"
                  className={'community-img'}
                  alt="img"
                  src="../static/images/home/icon-2.png"
                />
              </div>
              <h5>
                {intl.formatMessage({ id: 'Better Incentive Mechanisms' })}
              </h5>
              <p>
                {intl.formatMessage({
                  id: "Replacing middlemen with technology reduces transaction costs and rewards anyone who contributes to the transaction, whether they're service providers, consumers or others connected by the value-based internet."
                })}
              </p>
            </li>
            <li>
              <div>
                <StaticImage
                  placeholder="blurred"
                  className={'community-img icon3'}
                  alt="img"
                  src="../static/images/home/icon-3.png"
                />
              </div>
              <h5>
                {intl.formatMessage({ id: 'New Organization & Cooperation' })}
              </h5>
              <p>
                {intl.formatMessage({
                  id: 'The community economy managed by non-profit foundations will replace the monopolies of middlemen managed by traditional for-profit organizations. Based on open, fair, and rational rules, every contributor will benefit from, and become part of this economy.'
                })}
              </p>
            </li>
          </ul>
        </div>

        <div className='white-paper'>
          <a href="/files/AB Litepaper.pdf" target="_blank">
            {intl.formatMessage({ id: 'Litepaper' })}
          </a>
        </div>

        <div className={'centent-build'}>
          <ul>
            <li>
              <div className='title-box'>
                <span className='blue-dot'></span>
                <h2>{intl.formatMessage({ id: 'Start Building' })}</h2>
              </div>
              <p>
                {intl.formatMessage({
                  id: "AB's technology framework consists the basic technology layer, the hyper exchange protocol layer, and the application layer. All AB’s products will be on NewChain-TestNet first for public testing before official launching. The Technical Documentation provides developers with a comprehensive development guide."
                })}
              </p>
              <div className={'building'}>
                <a href="https://docs.ab.org/docs/" target="_blank">{intl.formatMessage({ id: 'Start Building' })}</a>
              </div>
            </li>
            <li>
              <div className='title-box'>
                <span className='blue-dot'></span>
                <h2>{intl.formatMessage({ id: 'Stake on AB' })}</h2>
              </div>
              <p>
                {intl.formatMessage({
                  id: 'NewChain nodes are the key co-constructers of AB infrastructure. AB NewChain Testnet and Mainnet are opened to the community, individual can deploy Testnet and Mainnet according to the instructions specified in this repo. Afer deploying the NewChain Mainnet, the Mainnet ledger nodes can produce blocks and get AB as the transaction fees from every transaction.'
                })}
              </p>
              <div className={'building in-web'}>
                {/* <Link to="/blog/2021/04/14/new-mining-cn/">{intl.formatMessage({ id: 'Stake' })}</Link>
                <Link to="/developers/docs/become-a-validator/">{intl.formatMessage({ id: 'Become a validator' })}</Link> */}
              </div>
            </li>
          </ul>
        </div>
        
      </div>
    </>
  )
}